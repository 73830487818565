<template>
  <g>
    <svg:style>
      .cls-3, .cls-4, .cls-5 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
      }

      .cls-3, .cls-4 {
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#handle-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient"
      :x1="doorLeftWidth1 + 70.82"
      :y1="doorTopHeight1 + 255.73"
      :x2="doorLeftWidth1 + 70.82"
      :y2="doorTopHeight1 + 45.07"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="handle-gradient"
      :x1="doorLeftWidth1 + 11.3"
      :y1="doorTopHeight1 + 150.4"
      :x2="doorLeftWidth1 + 16.96"
      :y2="doorTopHeight1 + 150.4"
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient"
      :x1="doorLeftWidth1 + 70.82"
      :y1="doorTopHeight1 + 240.89"
      :x2="doorLeftWidth1 + 70.82"
      :y2="doorTopHeight1 + 59.91"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="V28">
      <polyline id="inox"
                v-if="showInox"
                data-name="inox"
                class="cls-3"
                :points="`${doorLeftWidth1 + 88.5} ${doorTopHeight1 + 255.73} ${doorLeftWidth1 + 88.5} ${doorTopHeight1 + 45.07} ${doorLeftWidth1 + 53.15} ${doorTopHeight1 + 45.07} ${doorLeftWidth1 + 53.15} ${doorTopHeight1 + 255.73} ${doorLeftWidth1 + 88.5} ${doorTopHeight1 + 255.73}`"/>
      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-5"
                :points="`${doorLeftWidth1 + 83.55} ${doorTopHeight1 + 59.91} ${doorLeftWidth1 + 58.1} ${doorTopHeight1 + 59.91} ${doorLeftWidth1 + 58.1} ${doorTopHeight1 + 240.89} ${doorLeftWidth1 + 83.55} ${doorTopHeight1 + 240.89} ${doorLeftWidth1 + 83.55} ${doorTopHeight1 + 59.91}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
